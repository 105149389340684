$baseColor: #1aabe3;
$baseColorHover: #707070;

$baseColorTint:#1d1d1b;

$ctaButtonBordercolor: #db282f;
$ctaButtonPcolorHover: #fff;
$ctaButtonBordercolorHover:#e6696e;

$footerColor:$baseColorTint;

$font: "industry", sans-serif;